import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
//import { graphql } from "gatsby"
import { Link } from 'gatsby'
import { breakpoint, colors } from '../styles/variables'
import { StaticImage } from 'gatsby-plugin-image'

const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 1100px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 11rem;
    margin-bottom: 7rem;
  }
`

const BlogPosts = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media ${breakpoint.sm} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${breakpoint.md} {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  @media ${breakpoint.lg} {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
  }
`
const BlogPost =  styled.li`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 1fr;
  border-bottom: 2px solid black;
  transition: background-color 0.3s ease;

  @media ${breakpoint.sm} {
    grid-template-rows: 10rem 1fr;
    grid-template-columns: 1fr;
  }

  .fake-link {

    transition: color 0.3s ease;
    font-size: 0.875rem;
    margin: auto 0 0;

    .icon {
      margin-left: 0.5em;
    }
  }

  .gatsby-image-wrapper {
      transition: opacity 0.3s ease;
    }

  &:hover {
    background-color: #f49a411a;
    border-color: ${colors.primary};

    .gatsby-image-wrapper {
      opacity: 0.75;
    }
  }

  p.perex {
    display: none;
  }

  &:first-of-type {
    grid-column: 1/2;
    grid-template-rows: 10rem 1fr;
    grid-template-columns: 1fr;
    gap: .5rem;
    background-color: #404040;
    color: #fff;

    @media ${breakpoint.sm} {
      grid-column: 1/3;
      grid-template-columns: 1.5fr 2fr;
      grid-template-rows: 1fr;
      padding-right: 0.5rem;
    }

    h2 {
      @media ${breakpoint.sm} {
        margin-top: 1rem;
      }
    }

    p.perex {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 0.9375rem;
      opacity: 0.75;
    }

    &:hover {
      background-color:#404040b8;
    }
  }
`

const Title = styled.h2`
  text-transform: none;
  margin-bottom: 0.3rem;
  transition: color 0.3s ease;
  margin-top: 0;
  font-size: 1.2rem;
  line-height: 1.3;
`
const Date = styled.p`
  color: gray;
  font-size: 0.75rem;
  margin: 0;
`

const BlogLink = styled(Link)`
  display: block;
  position: absolute;
  inset: 0;
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${colors.primary};
  opacity: 0.75;

    ${({simona}) => `${simona && `
      color: #3e7d78;
  `}`}
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
`

const BlogPostBottom = styled.div`
   padding: 1.25rem 1rem;
   display: flex;
   flex-direction: column;
   position: relative;
`

const BlogPage = ({data, title, simona}) => {
    /* const posts = data.allMarkdownRemark.nodes */
    const posts = []
    if (posts.length === 0)
    {
      <>
      <SmallBanner title="blog"/>
        <Wrapper>
          Zatím zde nejsou žádné příspěvky
        </Wrapper>
       </>
    }
    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
          <BlogPosts>
            <BlogPost>
                <StaticImage src="../assets/images/blog/dublin/dublin_1.jpg" alt="Dublin"></StaticImage>
                  <BlogPostBottom>
                    <Title>Na víkend do Dublinu</Title>
                    <Info>
                      <Date>1. 3. 2024</Date>
                      <Author>Julia</Author>
                    </Info>
                    <p class="perex">Pokud máte před sebou jen tři dny a rozhodli jste se strávit je v Dublinu, připravte se na nezapomenutelnou cestu plnou piva, whiskey, tradičních pokrmů a živé hudby. Irská pohostinnost a kouzlo Dublinu vás jistě vtáhnou do svého světa.</p>
                    <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                  </BlogPostBottom>
                <BlogLink to="/na-vikend-do-dublinu"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
                <StaticImage src="../assets/images/blog/madeira/madeira_1.jpg" alt="Madeira"></StaticImage>
                  <BlogPostBottom>
                    <Title>Naše putování po Madeiře: Itinerář na 6 dní</Title>
                    <Info>
                      <Date>25. 1. 2024</Date>
                      <Author>Julia</Author>
                    </Info>
                    <p class="perex">Do Funchalu jsme přiletěly chvíli po půlnoci a tak jsme ihned zamířily najít taxi, které nás dopravilo do předem rezervovaného ubytování cca 15 min od letiště. Hned ráno jsme zamířily autobusem zpět na letiště, do půjčovny, kde jsme měly rezervované auto, které nám umožnilo plnou flexibilitu při poznávání ostrova. První zastávkou bylo Miradouro da Ponto do Rosto. Byly jsme naprosto uchvácené výhledy na oceán a pobřeží a těšily jsme se na to, co bude před námi následující dny. Po návratu do Funchalu jsem si užily jízdu lanovkou do Botanické zahrady, kde jsme si daly okouzlující procházku v obklopení exotických rostlin, obrovských kaktusů a úchvatné panorama města. První den jsme zakončily ubytováním v příjemném městečku Sao Vicente (Gardens Guest House Hostel).</p>
                    <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                  </BlogPostBottom>
                <BlogLink to="/nase-putovani-po-madeire-itinerar-na-6-dni"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
                <StaticImage src="../assets/images/blog/slovakia/slovakia-1.jpg" alt=""></StaticImage>
                  <BlogPostBottom>
                    <Title>5 Důvodů, Proč Navštívit Slovensko</Title>
                    <Info>
                      <Date>16. 8. 2023</Date>
                      <Author simona="true">Simona</Author>
                    </Info>
                    <p class="perex">Slovensko, pro některé Čechy tak blízké a přitom tak vzdálené. Ruku na srdce, určitě ve svém okolí máte někoho, kdo na Slovensku za svůj život ještě nikdy nebyl (a nemyslíme tím právě narozené miminko vaší sestry).</p>
                    <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                  </BlogPostBottom>
                <BlogLink to="/5-duvodu-proc-navstivit-slovensko"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
                <StaticImage src="../assets/images/blog/holand/holand-1.jpeg" alt=""></StaticImage>
                  <BlogPostBottom>
                    <Title>Letní Holandsko a Belgie</Title>
                    <Info>
                      <Date>16. 7. 2023</Date>
                      <Author>Julia</Author>
                    </Info>
                    <p class="perex">Většina z nás vyráží v létě k moři. V létě 2022 jsem se však rozhodla pro menší změnu. Místo válení na pláži a koupání v moři, jsme se rozhodli vydat po zemích Beneluxu.</p>
                    <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                  </BlogPostBottom>
                <BlogLink to="/letni-holandsko-a-belgie"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
              <StaticImage src="../assets/images/blog/senior/senior_1.jpg" alt="Senioři na dovolené"></StaticImage>
                <BlogPostBottom>
                  <Title>Nejpřívětivější destinace pro seniory</Title>
                  <Info>
                    <Date>11. 7. 2023</Date>
                    <Author simona="true">Simona</Author>
                  </Info>
                  <p class="perex">V dnešním článku vám představíme 5 nejpřívětivějších destinací, které nabízejí skvělé zážitky pro starší cestovatele. Bez ohledu na to, zda se jedná o příjemnou plážovou dovolenou, objevování kulturního bohatství nebo dobrodružství v přírodě, tyto destinace jsou ideální pro relaxaci, poznávání nových míst a vytváření vzpomínek. Připravte se na neuvěřitelné dobrodružství!</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/nejprivetivejsi-destinace-pro-seniory"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
              <StaticImage src="../assets/images/blog/mexico2/mexico2-1.jpg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Na měsíc v Mexiku</Title>
                  <Info>
                    <Date>25. 6. 2023</Date>
                    <Author simona="true">Simona</Author>
                  </Info>
                  <p class="perex">Jak to začalo? Na konci listopadu 2022 mi uběhla výpovědní lhůta v projektu, ve kterém jsem sice moc ráda pracovala, ale vlivem vnějších okolností jsem tuto loď, v rámci zachování vlastního duševního zdraví, raději opustila.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/na-mesic-v-mexiku"><span className="sr-only">Přečíst celý článek</span></BlogLink>
        </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/napoli/napoli3.jpeg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Na pizzu do Neapole? Klidně.</Title>
                  <Info>
                    <Date>18. 6. 2023</Date>
                    <Author>Julia</Author>
                  </Info>
                  <p className="perex">Na pizzu si můžeme dnes už zajít kdekoliv, ale co takhle si zaletět na prodloužený víkend do Neapole na pizzu? Máte pocit, že to bude hrozně drahé? No takhle, něco vás to stát bude, ale kolik konkrétně? Pojďme se na to podívat.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/na-pizzu-do-neapole"><span className="sr-only">Přečíst celý článek</span></BlogLink>
        </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/barcelona/barcelona1.jpeg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Barcelona</Title>
                  <Info>
                    <Date>12. 6. 2023</Date>
                    <Author simona="true">Simona</Author>
                  </Info>
                  <p className="perex">Poslední únorový víkend v roce 2022 jsem se vydala prozkoumat Barcelonu. Nebyla to moje první návštěva tohoto města, ale vzhledem k tomu, že poprvé jsem tady byla na jeden den před 12 lety a navíc ještě pod taktovkou CK, vzpomínky byly velmi matné. A tak jsem si chtěla vytvořit nové. Jak se mi to povedlo?</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/barcelona"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/tenTips/tenTips1.jpeg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Desatero cestování o samotě</Title>
                  <Info>
                    <Date>28. 5. 2023</Date>
                    <Author simona="true">Simona</Author>
                  </Info>
                  <p className="perex">Ne každý si to někdy vyzkoušel, ale říká se, že jak to jednou okusíte, už nechcete jinak.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/desatero-cestovani-o-samote"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/camino2/camino2-1.jpeg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Camino - jak jsem poznala Charlie</Title>
                  <Info>
                    <Date>17. 5 2023</Date>
                    <Author>Julia</Author>
                  </Info>
                  <p className="perex">Casa Alternativo - to byl cíl můj, Charlie a Vereny. Tak nějak večer, když jsme upíjely několikátou
                    skleničku výborného portugalského vína, mi holky o tomto Albergue začaly vyprávět. Moc se mi to
                    zalíbilo a představa, že po tak náročném večeru a těch 20-25 km denně, co jsem posledních pár
                    dní absolvovala, půjdu pouze 17, byla velmi lákavá.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/camino-jak-jsem-poznala-charlie"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/trek-nalehko/trek1.jpg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Jak se sbalit na trek na lehko</Title>
                  <Info>
                    <Date>7. 5 2023</Date>
                    <Author simona={true}>Simona</Author>
                  </Info>
                  <p className="perex">Na pár dovolených už jsem se sbalit dokázala, nikdy jsem ale nemusela vše, co budu potřebovat nosit 7 dní v kuse na zádech. To znamenalo jediné, dobře promyslet každý kousek oblečení, řasenku nebo knihu, kterou s sebou ponesu.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/jak-se-sbalit-na-trek-nalehko"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/rota/rota1.jpeg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Rota Vicentina v protisměru</Title>
                  <Info>
                    <Date>30. 4. 2023</Date>
                    <Author simona={true}>Simona</Author>
                  </Info>
                  <p className="perex">V září 2022 jsem se poprvé sama vydala na vícedenní pěší trek. Přípravy začaly zhruba o měsíc dřív nákupem letenek z Vídně do Fara, malého městečka na jihu Portugalska, kde moje dvou týdenní dobrodružství začalo.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/rota-vicentina-v-protismeru"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/camino/camino1.jpg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Camino - o samotě</Title>
                  <Info>
                    <Date>23. 4. 2023</Date>
                    <Author>Julia</Author>
                  </Info>
                  <p className="perex">Nejčastěji pokládanou otázkou na Caminu je - <b>proč jsi se rozhodl/a Camino jít?</b> Posledních pár let bylo pro mě ve znamení velkých změn - několikrát jsem se stěhovala, vyzkoušela jsem si, jaké to je odjet žít sama do jiné země, několikrát jsem změnila práci, několikrát se rozešla a zase se zamilovala, přišla o toho nejcennějšího člověka ve svém životě - tátu, nastěhovala si k sobě 7 členů své rodiny v důsledku války na Ukrajině a mohla bych pokračovat ještě dál.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/camino-o-samote"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
          <BlogPost>
              <StaticImage src="../assets/images/blog/israel/israel1.jpeg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Izrael, Jordánsko a Palestina</Title>
                  <Info>
                    <Date>16. 4. 2023</Date>
                    <Author simona={true}>Simona</Author>
                  </Info>
                  <p className="perex">V roce 2017 jsem se vydala netypicky na zájezd s cestovní kanceláří. Volba padla na CK, protože se jednalo o <b>Izrael/Palestinu a Jordánsko</b>, kam jsem si v té době netroufala vydat se na cestu úplně po vlastní ose.</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/izrael-jordansko-a-palestina"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
              <StaticImage src="../assets/images/blog/bergamo/bergamo1.jpeg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>5 důvodů, proč navštívit italské Bergamo</Title>
                  <Info>
                    <Date>6. 4. 2023</Date>
                    <Author simona={true}>Simona</Author>
                  </Info>
                  <p className="perex">Bergamo je město na severu Itálie v oblasti Lombardie přibližně 50 km východně od o něco známějšího Milána, města módy. Že jste o Bergamu slyšeli, ale nikdy tam nebyli? Nebo dokonce vůbec neslyšeli?</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/5-duvodu-proc-navstivit-italske-bergamo"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
              <StaticImage src="../assets/images/blog/mexico/mexico-illustration.jpg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Mexiko - jak neutratit víc, než je potřeba</Title>
                  <Info>
                    <Date>18. 3. 2023</Date>
                    <Author>Julia</Author>
                  </Info>
                  <p className="perex">Na cestě vždy narazíte na férové lidi. Bohužel občas i na takové, kteří se snaží využít Vaší neznalosti ve svůj prospěch. Projela jsem mnoho zemí a mohu s klidným svědomím říct, že podvody a podrazy se dějí všude - od Evropy, přes Asii, až po Ameriku. Svět není vždy jen o hodných lidech. Naštěstí je těch poctivých více a většina cest je plná hezkých zážitků. Je však dobré být připraven i na ty horší a nepříjemné situace. Tady je pár rad na základě osobní zkušenosti:</p>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/mexiko-jak-neutratit-vic-nez-je-potreba"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>

            <BlogPost>
              <StaticImage src="../assets/images/blog/riga/riga9.jpg" alt=""></StaticImage>
              <BlogPostBottom>
                <Title>7 důvodů, proč miluji Rigu</Title>
                <Info>
                  <Date>5. 12. 2022</Date>
                  <Author>Julia</Author>
                </Info>
                <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
              </BlogPostBottom>
              <BlogLink to="/proc-miluji-rigu"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>

            <BlogPost>
                <StaticImage src="../assets/images/blog/cambodia3.jpg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Kambodža  - do třetice všeho dobrého</Title>
                  <Info>
                    <Date>14. 11. 2022</Date>
                    <Author>Julia</Author>
                  </Info>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/kambodza-do-tretice-vseho-dobreho"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
              <StaticImage src="../assets/images/blog/cambodia1.jpg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Kambodža - Dobrodružství není nikdy dost!</Title>
                  <Info>
                    <Date>14. 11. 2022</Date>
                    <Author>Julia</Author>
                  </Info>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/kambodza-dobrodruzstvi-neni-nikdy-dost"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
            <BlogPost>
               <StaticImage src="../assets/images/blog/thailand1.jpg" alt=""></StaticImage>
                <BlogPostBottom>
                  <Title>Není dobrodružství jako dobrodružství</Title>
                  <Info>
                    <Date>11. 9. 2022</Date>
                    <Author>Julia</Author>
                  </Info>
                  <p className="fake-link" aria-hidden="true">Číst více<span className="icon icon-arrow-right"></span></p>
                </BlogPostBottom>
              <BlogLink to="/neni-dobrodruzstvi-jako-dobrodruzstvi"><span className="sr-only">Přečíst celý článek</span></BlogLink>
            </BlogPost>
          </BlogPosts>
            {/* {<ul>
              {posts.map(post => {
                return (
                  <><article
                    className="blog-post"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <h1 itemProp="headline">{post.frontmatter.title}</h1>
                      <p>{post.frontmatter.date}</p>
                    </header>
                    <section
                      dangerouslySetInnerHTML={{ __html: post.html }}
                      itemProp="articleBody" />
                    <hr />
                    <footer>
                      <p>test footer</p>
                    </footer>
                  </article></>
                )
              })}
            </ul> } */}
        </Wrapper>
       </>
    )
  }


  export default BlogPage

  export const Head = () => (
    <>
      <title>Blog | Cestu-j</title>
      <meta name="description" content="Cestovní itineráře na míru" />
    </>
  )

  /* export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]}
      filter: {frontmatter: {layout: {eq: "blog"}}}
    ) {
      nodes {
        id
        frontmatter {
          date
          title
        }
        html
      }
    }
  }
` */

